<template>
	<div id="adoptList">
		<page-adopt-list is-shop="0"></page-adopt-list>
	</div>
</template>

<script>
	import PageAdoptList from '@/components/layout/adopt/page-adopt-list.vue'
	export default{
		components:{
			PageAdoptList
		},
		inject:["reload"],		//刷新页面
		beforeRouteLeave (to, from, next) {
		    from.meta.keepAlive = false;
		    next();
		},
	}
</script>

<style lang="scss">
	.adopt-search-input{
		width: 350px!important;
	}
	.kd-adoptlist{
		width: 100%;
		display: flex;
  	
		&-cover{
			width: 50px;
			height: 50px;
			border-radius: 5px;
			margin-right: 10px;
		}
	}
</style>
